import React from 'react';
import { View, Text, StyleSheet, Image } from '@react-pdf/renderer';
import moment from 'moment';

const styles = StyleSheet.create({
  footer: {
    position: 'absolute',
    bottom: 10,
    left: 20,
    right: 25,
    flexDirection: 'row',
    fontSize: 8,
    fontWeight: 'heavy',
    marginTop: 5,
    fontFamily: 'Roboto-Regular'
  },
  image: {
    width: 50
  }
});

const Footer = (props) => {

  return (
    <>
      <View fixed style={styles.footer}>
        <View style={{ alignItems: "flex-start", textAlign: "left", width: "70%" }} >
          <Text style={styles.details}>Emitido em {moment(new Date).format('DD/MM/YYYY HH:mm')} por {JSON.parse(localStorage.getItem('user'))["nome"]}</Text>
        </View>
        <View style={{ textAlign: "right", width: "30%" }} >
          {/*<Text fixed render={({ pageNumber, totalPages }) => (*/}
          {/*  `Página ${pageNumber} de ${totalPages}`*/}
          {/*)} />*/}
          <Text fixed render={({ subPageNumber, subPageTotalPages }) => (
            `Página ${subPageNumber} de ${subPageTotalPages}`
          )} />
        </View>
      </View>


    </>
  )

};

export { Footer };
