import { crudService } from '../_services'
import { history } from 'utils'

export const retrospectivaAction = {
  getRetrospectiva,
  isLoading,
  clear
}

function getRetrospectiva(token) {
  return (dispatch) => {
    dispatch(isLoading())
    //dispatch(request({ clienteId }))
    let apiEndpoint = 'retrospectiva/2024'
    crudService
      .getWithToken(apiEndpoint, token)
      .then((response) => {
        dispatch(notIsLoading())
        dispatch(success(response.data))
      })
      .catch((error) => {
        dispatch(notIsLoading())
        if (error.status == 404) {
          history.push('/not-found')
        }
        dispatch(failure(error))
      })
  }
  function request(lojaId) {
    return { type: 'FETCHED_RETROSPECTIVA_REQUEST', lojaId }
  }

  function success(response) {
    return {
      type: 'FETCHED_RETROSPECTIVA_SUCCESS',
      nome: response.nome,
      totalFidelizacoes: response.totalFidelizacoes,
      totalLojas: response.totalLojas,
      nomeLojaFavorita: response.nomeLojaFavorita,
      pontuacao: response.pontuacao,
      totalItens: response.totalItens,
      top: response.top,
      nomeLojaTop: response.nomeLojaTop
    }
  }

  function failure(error) {
    return { type: 'FETCHED_RETROSPECTIVA_FAILURE', error }
  }
}

export function clear() {
  return {
    type: 'RETROSPECTIVA_CLEAR'
  }
}

function isLoading() {
  return {
    type: 'RETROSPECTIVA_ISLOADING'
  }
}

export function notIsLoading() {
  return {
    type: 'RETROSPECTIVA_NOTISLOADING'
  }
}
