import React, { useState, useEffect } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { styled, withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import LabelTwoToneIcon from '@material-ui/icons/LabelTwoTone';
import {
  Box,
  Button,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Tab,
  Tabs,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,

} from '@material-ui/core'
import Skeleton from "@material-ui/lab/Skeleton";
import {
  SelectValidator,
  TextValidator,
  ValidatorForm
} from 'react-material-ui-form-validator'
import {
  compraAction,
  lojaAction,
  fornecedorFilialAction,
  fornecedorInfoAction,
  totvsInsumoConversaoAction,
  totvsInsumoUnidadeAction,
  categoriaCompraAction,
  subcategoriaCompraAction
} from '../../_actions'
import { withRouter } from 'react-router-dom'
import { history, cnpjMask } from '../../utils'
import SaveIcon from '@material-ui/icons/Save'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile'
import ThumbUpTwoToneIcon from '@material-ui/icons/ThumbUpTwoTone';
import ThumbDownTwoToneIcon from '@material-ui/icons/ThumbDownTwoTone';
import { EditorHeader, Page } from 'components'
import InfoIcon from '@material-ui/icons/Info'
import { isEmpty } from 'lodash'
import moment from 'moment'
import { CompraClassificarFornecedorModal } from './compraClassificarFornecedorModal.component'
import { CompraClassificarInsumoModal } from './compraClassificarInsumoModal.component'
import { CompraInfoNcmModal } from './compraInfoNcmModal.component'
import { CompraInfoGtinModal } from './compraInfoGtinModal.component'
import { CompraInfoFornecedorModal } from './compraInfoFornecedorModal.component'
import { AccessControl } from '../../components/Utils/AccessControl'

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlgin: 'center'
  },
  nome: {
    marginTop: theme.spacing(1)
  },
  uploadButton: {
    marginRight: theme.spacing(2),
    justifyContent: 'space-between'
  },
  avatar: {
    height: 200,
    width: 200,
    borderRadius: '10px',
    webkitBorderRadius: '10px',
    mozBorderRadius: '10px',
    cursor: 'pointer'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  buttonDownload: {
    minWidth: '180px'
  }
})

const CompraEdit = (props) => {

  const dispatch = useDispatch()
  const { classes, compra, className, fornecedorFilial, totvsInsumoConversao, ...rest } = props
  const { match: { params } } = props;
  const { categoriaCompra } = props.categoriaCompra
  const { subcategoriaCompra } = props.subcategoriaCompra
  const formRef = React.createRef(null);

  const [modal, setModal] = useState(false);
  const [modalCnpj, setModalCnpj] = useState(false);
  const [modalNcm, setModalNcm] = useState(false);
  const [modalGtin, setModalGtin] = useState(false);

  const [modalInsumo, setModalInsumo] = useState(false);

  const [compraProduto, setCompraProduto] = useState(null);

  const [tab, setTab] = useState(0);
  const [filteredComprasProdutos, setFilteredComprasProdutos] = useState([]);

  const [totvsInsumo, setTotvsInsumo] = useState('');
  const [openModal, setOpenModal] = useState(false);

  const toggle = () => setModal(!modal);
  const toggleInsumo = () => setModalInsumo(!modalInsumo);
  const toggleNcm = () => setModalNcm(!modalNcm);
  const toggleGtin = () => setModalGtin(!modalGtin);
  const toggleCnpj = () => setModalCnpj(!modalCnpj);

  const { isLoading } = props.compra

  const fornecedoresFiliais = useSelector((state) => state.fornecedorFilial.fornecedorFilial)

  useEffect(() => {
    if (params.id) {
      dispatch(compraAction.getCompraById(params.id))
    } else {
      dispatch(compraAction.clear())
    }

    dispatch(lojaAction.getLojaSelect())
    dispatch(fornecedorFilialAction.getFornecedorFilialSelect())
    dispatch(categoriaCompraAction.getCategoriaSelect())
    dispatch(subcategoriaCompraAction.getSubcategoriaSelect())
    dispatch(totvsInsumoUnidadeAction.clear())

    // limpa somente na primeira vez, ao entrar na tela
    dispatch(totvsInsumoConversaoAction.clear())

  }, []);

  useEffect(() => {
    if (props.compra.comprasProdutos) {
      setFilteredComprasProdutos(props.compra.comprasProdutos.filter(filterTerm))
    } else {
      setFilteredComprasProdutos(null)
    }
  }, [tab, props.compra.comprasProdutos]);

  function filterTerm(obj) {
    if (tab == null || tab == '' || (obj.idCategoriaCompra == tab)) {
      return true;
    } else {
      return false;
    }
  }

  const getTotalComprasPorCategoria = (idCategoria) => {
    let total = (props.compra.comprasProdutos ? props.compra.comprasProdutos.filter(cp => cp.idCategoriaCompra != null && cp.idCategoriaCompra == idCategoria) : 0)

    return total.length
  }

  const handleTabsChange = (event, value) => {
    setTab(value);
  }

  const handleChange = (prop) => (event) => {
    dispatch(compraAction.onChangeProps(prop, event))
  }

  const handleOpenModal = () => {
    toggle();
  }

  const handleOpenModalInsumo = (n) => {

    setCompraProduto(n);

    toggleInsumo();
  }

  const handleOpenModalAprovado = (actualCompraProduto) => {
    setCompraProduto(actualCompraProduto)

    setOpenModal(true)
  }

  const handleCloseModalAprovado = () => {
    setOpenModal(false)
  }

  const handleChangeAprovado = () => {
    let payload = {
      ...compraProduto,
      aprovado: !compraProduto.aprovado
    }

    if (compraProduto.id !== 0) {
      dispatch(compraAction.editCompraProduto(compraProduto.id, payload))
    }

    setOpenModal(false)
  }

  const getDescritivoValor = (compra) => {

    var desc = "";

    if (compra.valorIPI != null && compra.valorIPI > 0) {
      desc += "Valor IPI R$ " + compra.valorIPI.toLocaleString('pt-br', { minimumFractionDigits: 2 }) + '\r\n';
    }
    if (compra.valorICMSST != null && compra.valorICMSST > 0) {
      desc += "Valor ICMS ST R$ " + compra.valorICMSST.toLocaleString('pt-br', { minimumFractionDigits: 2 }) + '\r\n';
    }
    if (compra.valorOutros != null && compra.valorOutros > 0) {
      desc += "Outros R$ " + compra.valorOutros.toLocaleString('pt-br', { minimumFractionDigits: 2 }) + '\r\n';
    }

    return desc;
  }

  const handleSubmit = (event) => {
    let payload = {
      id: params.id,
      nome: props.compra.nome,
      email: props.compra.email,
      integracao: props.compra.integracao,
      integracaoArquivo: props.compra.integracaoArquivo,
      comprasLojas: props.compra.todasLojas == false ? props.compra.comprasLojas : []
    }

    if (params.id) {
      dispatch(compraAction.editCompraInfo(params.id, payload, true))
    } else {
      dispatch(compraAction.createCompra(payload))
    }
  };

  const handleSearchGtinInfo = (gtin) => {
    if (gtin == props.compra?.gtinInfoDetail?.gtin) {
      setModalGtin(true);
    } else {
      dispatch(compraAction.getGtinInfo(gtin));
      setModalGtin(true);
    }
  }

  const handleSearchNcmInfo = (ncm) => {
    if (ncm == props.compra?.ncmInfoDetail?.code) {
      setModalNcm(true);
    } else {
      dispatch(compraAction.getNcmInfo(ncm));
      setModalNcm(true);
    }
  }

  const handleSearchInfoFornecedor = (cnpj) => {
    if (cnpjMask(cnpj) === props.fornecedorInfo.cnpj) {
      setModalCnpj(true)
    } else {
      dispatch(fornecedorInfoAction.getInfoModal(cnpj));
      setModalCnpj(true);
    }
  }

  function InsertText() {
    return (
      <Typography variant="h4">
        <b>{'Novo Compra'}</b>
      </Typography>
    )
  }

  const CustomTableCell = styled(TableCell)(() => ({ borderBottom: 0 }));

  return (
    <>
      <Page
        className={classes.root}
        title={
          params.id
            ? 'Gerência de Compras - Editar Compra'
            : 'Gerência de Compras - Nova Compra'
        }
      >
        <Grid
          {...rest}
          className={clsx(classes.root, className)}
          container
          spacing={3}
        >
          <Grid item lg={12} md={12} xl={12} xs={12}>
            <Grid item md={12} xs={12}>
              <Card>
                <ValidatorForm
                  className={classes.form}
                  ref={formRef}
                  id="formCompra"
                  onSubmit={(event) => handleSubmit(event)}
                >
                  <CardHeader
                    subheader="Cadastro de Compras"
                    title={(
                      params.id ?
                        <EditorHeader title='Editar Compra' tabela='Compra' chave={params.id} props={props} />
                        :
                        <InsertText />
                    )}
                  />
                  <Divider />
                  <CardContent >
                    <Grid container spacing={2}>
                      <Grid item lg={3} md={3} xl={3} xs={12}>
                        <TextValidator
                          fullWidth
                          type="date"
                          id="dataHora"
                          InputLabelProps={{ shrink: true }}
                          label="Data/Hora *"
                          margin="normal"
                          onChange={handleChange('dataHora')}
                          value={props.compra.dataHora ? moment(props.compra.dataHora).format("yyyy-MM-DD") : ''}
                          variant="outlined"
                          disabled
                        />
                      </Grid>
                      <Grid item lg={3} md={3} xl={3} xs={12} style={{ position: 'relative' }}>
                        <TextValidator
                          className={classes.textField}
                          fullWidth
                          id="cnpjFornecedor"
                          margin="normal"
                          label="CNPJ Fornecedor *"
                          onChange={handleChange('cnpjFornecedor')}
                          value={cnpjMask(props.compra.cnpjFornecedor)}
                          validators={['required']}
                          errorMessages={['Campo obrigatório!']}
                          variant="outlined"
                          disabled
                        />
                        {(props.compra?.cnpjFornecedor && props.compra?.id) &&
                          <Button
                            style={{ width: '24px', minWidth: '24px', padding: 0, borderRadius: '12px', position: 'absolute', top: '40px', right: '15px' }}
                            onClick={() => handleSearchInfoFornecedor(props.compra.cnpjFornecedor)}
                          >
                            <InfoIcon />
                          </Button>
                        }
                      </Grid>
                      <Grid item lg={6} md={6} xl={6} xs={12} style={{ position: 'relative' }}>
                        <TextValidator
                          className={classes.textField}
                          fullWidth
                          id="nomeFornecedor"
                          margin="normal"
                          label="Nome Fornecedor *"
                          onChange={handleChange('nomeFornecedor')}
                          value={props.compra.nomeFornecedor || ''}
                          validators={['required']}
                          errorMessages={['Campo obrigatório!']}
                          variant="outlined"
                          disabled
                        />
                      </Grid>
                      <Grid item lg={4} md={4} xl={4} xs={12}>
                        <TextValidator
                          className={classes.textField}
                          fullWidth
                          id="valor"
                          label="Valor *"
                          margin="normal"
                          type="number"
                          onChange={handleChange('valor')}
                          value={props.compra.valor || 0}
                          validators={['required']}
                          errorMessages={['Campo obrigatório!']}
                          variant="outlined"
                          disabled
                        />
                      </Grid>
                      <Grid item lg={4} md={4} xl={4} xs={12}>
                        <TextValidator
                          className={classes.textField}
                          fullWidth
                          id="valorDesconto"
                          label="Valor Desconto *"
                          margin="normal"
                          type="number"
                          onChange={handleChange('valorDesconto')}
                          value={props.compra.valorDesconto || 0}
                          validators={['required']}
                          errorMessages={['Campo obrigatório!']}
                          variant="outlined"
                          disabled
                        />
                      </Grid>
                      <Grid item lg={4} md={4} xl={4} xs={12}>
                        <TextValidator
                          className={classes.textField}
                          fullWidth
                          id="chave"
                          label="Chave *"
                          margin="normal"
                          onChange={handleChange('chave')}
                          value={props.compra.chave || ''}
                          validators={['required']}
                          errorMessages={['Campo obrigatório!']}
                          variant="outlined"
                          disabled
                        />
                      </Grid>
                      <Grid item lg={6} md={6} xl={6} xs={12} style={{ position: 'relative' }}>
                        <TextValidator
                          className={classes.textField}
                          fullWidth
                          id="natureza"
                          margin="normal"
                          label="Natureza"
                          onChange={handleChange('natureza')}
                          value={props.compra.natureza || ''}
                          variant="outlined"
                          disabled
                        />
                      </Grid>
                      <Grid item lg={5} md={5} xl={5} xs={11} style={{ alignItems: 'center', justifyContent: 'center', alignContent: 'center' }} >
                        <SelectValidator
                          validators={['required']}
                          errorMessages={['Campo obrigatório!']}
                          variant="outlined"
                          id="idFornecedorFilial"
                          label="Fornecedor"
                          value={props.compra.idFornecedorFilial}
                          onChange={handleChange('idFornecedorFilial')}
                          InputLabelProps={{
                            shrink: true
                          }}
                          fullWidth
                          displayEmpty
                          labelId="idFornecedorFilial"
                        >
                          <MenuItem disabled value="" key={12}>
                            <em>Fornecedor *</em>
                          </MenuItem>
                          {!isEmpty(fornecedoresFiliais) &&
                            undefined !== fornecedoresFiliais &&
                            fornecedoresFiliais.length &&
                            fornecedoresFiliais.map((row, index) => (
                              <MenuItem key={'u' + index} value={row.value}>
                                {row.text}
                              </MenuItem>
                            ))}
                        </SelectValidator>
                      </Grid>
                      <AccessControl
                        rule={'compras.edit'} //permissão necessária para acessar conteúdo
                        yes={() => (
                          <>
                            <Grid item md={1} xs={1} style={{ marginTop: '5px', marginBottom: '5px' }}>
                              <IconButton onClick={handleOpenModal}>
                                <Tooltip title="Classificar fornecedor">
                                  <LabelTwoToneIcon />
                                </Tooltip>
                              </IconButton>
                            </Grid>
                          </>
                        )}
                      />
                      <Grid item md={12} xs={12} style={{ marginTop: '5px', marginBottom: '5px' }}>
                        <Divider />
                      </Grid>

                      <Tabs
                        className={classes.tabs}
                        onChange={handleTabsChange}
                        scrollButtons="auto"
                        value={tab}
                        indicatorColor="primary"
                        textColor="primary"
                        variant={'scrollable'}
                      >
                        <Tab
                          key={0}
                          label={"TODOS" + (props.compra.comprasProdutos && props.compra.comprasProdutos.length ? " (" + props.compra.comprasProdutos.length + ")" : "")}
                          value={0}
                        />
                        {categoriaCompra.map((tab) => (
                          <Tab
                            key={Number(tab.value)}
                            label={tab.text + (getTotalComprasPorCategoria(tab.value) ? " (" + getTotalComprasPorCategoria(tab.value) + ")" : "")}
                            value={Number(tab.value)}
                          />
                        ))}
                      </Tabs>

                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Produto</TableCell>
                              <TableCell>NCM/Cód. Int.</TableCell>
                              <TableCell>CFOP</TableCell>
                              <TableCell key="comercial/tributavel"></TableCell>
                              <TableCell>CEAN/GTIN</TableCell>
                              <TableCell>Unidade</TableCell>
                              <TableCell>Qntd.</TableCell>
                              <TableCell>Valor Un.</TableCell>
                              <TableCell>Valor</TableCell>
                              <TableCell>Impostos e Outros</TableCell>
                              <TableCell>Subcategoria/Insumo</TableCell>
                              <TableCell key="classificarModalIconButton"></TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {filteredComprasProdutos !== undefined && filteredComprasProdutos.length
                              ? filteredComprasProdutos.map((n) => {
                                return (
                                  <TableRow hover key={n.id}>
                                    <TableCell>
                                      {n.descricao || ''}
                                    </TableCell>
                                    <TableCell>
                                      <TableRow>
                                        <TableCell>
                                          <Box style={{ display: 'flex', flex: 1, flexDirection: 'row', alignItems: 'center', gap: '10px' }}>
                                            <Typography>{n.ncm || ''}</Typography>
                                            {n.ncm &&
                                              <Button style={{ width: '24px', minWidth: '24px', padding: 0, borderRadius: '12px' }} onClick={() => handleSearchNcmInfo(n.ncm)} >
                                                <InfoIcon />
                                              </Button>
                                            }
                                          </Box>
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell>
                                          {n.codigoProduto || ''}
                                        </TableCell>
                                      </TableRow>
                                    </TableCell>
                                    <TableCell>
                                      <TableRow>
                                        <TableCell>
                                          {n.cfop || ''}
                                        </TableCell>
                                      </TableRow>
                                    </TableCell>
                                    <TableCell align="right">
                                      <TableRow>
                                        <TableCell>
                                          <b>Comercial</b>
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell>
                                          <b>Tributável</b>
                                        </TableCell>
                                      </TableRow>
                                    </TableCell>

                                    <TableCell>
                                      <TableRow>
                                        <TableCell style={{ display: 'flex', flex: 1, flexDirection: 'row', alignItems: 'center', gap: '10px' }}>
                                          <Typography>{n.cean || ''}</Typography>
                                          {(n.cean && n.cean != 'SEM GTIN') &&
                                            <Button style={{ width: '24px', minWidth: '24px', padding: 0, borderRadius: '12px' }} onClick={() => handleSearchGtinInfo(n.cean)} >
                                              <InfoIcon />
                                            </Button>
                                          }
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell style={{ display: 'flex', flex: 1, flexDirection: 'row', alignItems: 'center', gap: '10px' }}>
                                          <Typography>{n.ceanTributavel || ''}</Typography>
                                          {(n.ceanTributavel && n.ceanTributavel != 'SEM GTIN') &&
                                            <Button style={{ width: '24px', minWidth: '24px', padding: 0, borderRadius: '12px' }} onClick={() => handleSearchGtinInfo(n.ceanTributavel)} >
                                              <InfoIcon />
                                            </Button>
                                          }
                                        </TableCell>
                                      </TableRow>
                                    </TableCell>

                                    <TableCell align="center">
                                      <TableRow>
                                        <TableCell>
                                          {(n.unidade ? n.unidade.abreviacao : '')}
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell>
                                          {(n.unidadeTributavel ? n.unidadeTributavel.abreviacao : '')}
                                        </TableCell>
                                      </TableRow>
                                    </TableCell>

                                    <TableCell align="center">
                                      <TableRow>
                                        <TableCell>
                                          {n.quantidade || 0}
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell>
                                          {n.quantidadeTributavel || 0}
                                        </TableCell>
                                      </TableRow>
                                    </TableCell>

                                    <TableCell align="center">
                                      <TableRow>
                                        <TableCell style={{ whiteSpace: 'nowrap' }}>
                                          {n.valorUnitario ? 'R$ ' + n.valorUnitario.toLocaleString('pt-br', { minimumFractionDigits: 2 }) : ''}
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell style={{ whiteSpace: 'nowrap' }}>
                                          {n.valorUnitarioTributavel ? 'R$ ' + n.valorUnitarioTributavel.toLocaleString('pt-br', { minimumFractionDigits: 2 }) : ''}
                                        </TableCell>
                                      </TableRow>
                                    </TableCell>

                                    <TableCell align="right" style={{ whiteSpace: 'nowrap' }}>
                                      {n.valor ? 'R$ ' + n.valor.toLocaleString('pt-br', { minimumFractionDigits: 2 }) : ''}
                                    </TableCell>

                                    <TableCell align="right" style={{ whiteSpace: 'nowrap' }}>
                                      <Tooltip title={<div style={{ whiteSpace: 'pre-line' }}>{getDescritivoValor(n)}</div>}>
                                        <Typography>{n.valorIPI || n.valorICMSST || n.valorOutros ? 'R$ ' + ((n.valorIPI || 0) + (n.valorICMSST || 0) + (n.valorOutros || 0)).toLocaleString('pt-br', { minimumFractionDigits: 2 }) : ''}</Typography>
                                      </Tooltip>
                                    </TableCell>

                                    <TableCell>
                                      <TableRow>
                                        <TableCell>
                                          <b>{n.subcategoriaCompra ? n.subcategoriaCompra.nome : ''}</b>
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell>
                                          <b>{n.totvsInsumo ? n.totvsInsumo.nome : ''}</b>
                                        </TableCell>
                                      </TableRow>
                                    </TableCell>

                                    <TableCell align="right">

                                      <AccessControl
                                        rule={'compras.edit'} //permissão necessária para acessar conteúdo
                                        yes={() => (
                                          <>
                                            <TableRow>
                                              <IconButton onClick={() => handleOpenModalInsumo(n)}>
                                                <Tooltip title="Classificar insumo">
                                                  <LabelTwoToneIcon />
                                                </Tooltip>
                                              </IconButton>
                                            </TableRow>
                                          </>
                                        )}
                                      />

                                      <AccessControl
                                        rule={'compras.edit'} //permissão necessária para acessar conteúdo
                                        yes={() => (
                                          <>
                                            <TableRow>
                                              <IconButton onClick={() => handleOpenModalAprovado(n)}>
                                                <Tooltip title={(n.aprovado ? "Reprovar" : "Aprovar") + " produto"}>
                                                  {n.aprovado ? <ThumbDownTwoToneIcon /> : <ThumbUpTwoToneIcon />}
                                                </Tooltip>
                                              </IconButton>
                                            </TableRow>
                                          </>
                                        )}
                                      />

                                    </TableCell>
                                  </TableRow>
                                )
                              })
                              :
                              (
                                isLoading ?
                                  Array.apply(null, { length: 6 }).map((e, i) => (
                                    <TableRow hover>
                                      <TableCell component="th" scope="row">
                                        <Skeleton animation="wave" />
                                      </TableCell>
                                      <TableCell component="th" scope="row">
                                        <Skeleton animation="wave" />
                                      </TableCell>
                                      <TableCell component="th" scope="row">
                                        <Skeleton animation="wave" />
                                      </TableCell>
                                      <TableCell component="th" scope="row">
                                        <Skeleton animation="wave" />
                                      </TableCell>
                                      <TableCell component="th" scope="row" align="right">
                                        <Skeleton animation="wave" />
                                      </TableCell>
                                      <TableCell component="th" scope="row" align="right">
                                        <Skeleton animation="wave" />
                                      </TableCell>
                                      <TableCell component="th" scope="row" align="right">
                                        <Skeleton animation="wave" />
                                      </TableCell>
                                      <TableCell component="th" scope="row">
                                        <Skeleton animation="wave" />
                                      </TableCell>
                                      <TableCell component="th" scope="row" align="right">
                                        <Skeleton animation="wave" />
                                      </TableCell>
                                      <TableCell component="th" scope="row" align="right">
                                        <Skeleton animation="wave" />
                                      </TableCell>
                                      <TableCell component="th" scope="row" align="right">
                                        <Skeleton animation="wave" />
                                      </TableCell>
                                      <TableCell component="th" scope="row" align="right">
                                        <Skeleton animation="wave" />
                                      </TableCell>
                                    </TableRow>
                                  ))
                                  :
                                  <TableRow>
                                    <TableCell rowSpan={3} colSpan={12} align="center">Nenhum registro encontrado</TableCell>
                                  </TableRow>
                              )
                            }
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                    <Grid item md={12} xs={12} style={{ marginTop: '5px', marginBottom: '5px' }}>
                      <Divider />
                    </Grid>
                    <Grid md={12} xs={12} style={{ flexDirection: 'row' }}>
                      <Grid item lg={12} md={12} xl={12} xs={12}>
                        <Button
                          color="default"
                          onClick={() => dispatch(compraAction.getXml(compra.id, compra.chave))}
                          variant="contained"
                          startIcon={<InsertDriveFileIcon />}
                          disabled={props.compra.isLoading}
                          endIcon={props.compra.isLoading && <CircularProgress size={24} />}
                          className={classes.buttonDownload}
                        >
                          Arquivo XML
                        </Button>
                      </Grid>
                      <Grid item lg={12} md={12} xl={12} xs={12}>
                        <Button
                          color="default"
                          onClick={() => dispatch(compraAction.getPdf(compra.id, compra.chave))}
                          variant="contained"
                          startIcon={<PictureAsPdfIcon />}
                          disabled={props.compra.isLoading}
                          endIcon={props.compra.isLoading && <CircularProgress size={24} />}
                          className={classes.buttonDownload}
                        >
                          Arquivo PDF
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <Divider />
                  <CardActions>
                    <Grid item lg={12} md={12} xl={12} xs={12}>
                      <Typography style={{ fontSize: "10px", paddingLeft: "6px" }}>
                        * Campos obrigatórios
                      </Typography>

                    </Grid>

                    <Grid item lg={12} md={12} xl={12} xs={12} style={{
                      display: 'flex',
                      alignItems: 'right',
                      flexDirection: 'row',
                      textAlgin: 'right',
                      justifyContent: 'right'
                    }}>
                      <Button
                        color="default"
                        onClick={() => history.push('/Compras')}
                        variant="contained"
                      >
                        Cancelar
                      </Button>
                      <AccessControl
                        rule={'compras.edit'} //permissão necessária para acessar conteúdo
                        yes={() => (
                          <Button
                            color="primary"
                            type="submit"
                            variant="contained"
                            disabled={props.compra.isLoading}
                            startIcon={<SaveIcon />}
                            endIcon={props.compra.isLoading && <CircularProgress size={24} />}
                          >
                            {params.id ? (props.compra.isLoading ? 'Atualizando compra...' : 'Atualizar') : (props.compra.isLoading ? 'Salvando compra...' : 'Salvar')}
                          </Button>
                        )}
                      />
                    </Grid>
                  </CardActions>
                </ValidatorForm>
              </Card>
            </Grid>
          </Grid>
        </Grid>

        {modalInsumo && <CompraClassificarInsumoModal toggle={toggleInsumo} modal={modalInsumo} props={props} idTotvsInsumoConversao={totvsInsumoConversao.id || 0} totvsInsumoSelected={null} idCompra={params.id} compraProduto={compraProduto} categorias={categoriaCompra} subcategorias={subcategoriaCompra} />}
        {modal && <CompraClassificarFornecedorModal toggle={toggle} modal={modal} cnpjFornecedor={props.compra.cnpjFornecedor} props={props} fornecedorFilial={fornecedorFilial} idCompra={params.id} />}
        {modalNcm && <CompraInfoNcmModal toggle={toggleNcm} modal={modalNcm} ncm={props.compra.ncmInfoDetail} loading={props.compra.isLoading} />}
        {modalGtin && <CompraInfoGtinModal toggle={toggleGtin} modal={modalGtin} gtin={props.compra.gtinInfoDetail} loading={props.compra.isLoading} />}
        {modalCnpj && <CompraInfoFornecedorModal toggle={toggleCnpj} modal={modalCnpj} fornecedor={props.fornecedorInfo} />}

        {compraProduto != null &&
          <Dialog
            open={openModal}
            onClose={handleCloseModalAprovado}
          >
            <DialogTitle>{`Deseja ${compraProduto.aprovado ? 'reprovar' : 'aprovar'} esse produto?`}</DialogTitle>
            <DialogContent>
              {compraProduto.aprovado ?
                <DialogContentText>
                  Ao <b>reprovar</b> esse produto, ele <b>não será</b> considerado nos preços médios (relatórios e dashboards).
                </DialogContentText>
                :
                <DialogContentText>
                  Ao <b>aprovar</b> esse produto, ele <b>será</b> considerado nos preços médios (relatórios e dashboards).
                </DialogContentText>
              }
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseModalAprovado} color="primary">
                Cancelar
              </Button>
              <Button onClick={handleChangeAprovado} color="primary" autoFocus>
                Confirmar
              </Button>
            </DialogActions>
          </Dialog>
        }

      </Page >
    </>
  )
}

CompraEdit.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return state
}

const connectedCompraEditPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(CompraEdit))
)
export { connectedCompraEditPage as CompraEdit }
